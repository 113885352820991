import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AppInfo from "../../components/app-info"
import Icon from "../../components/images/icons/image_icon_pf"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <AppInfo
      Icon={Icon}
      appName="素因数分解・素数判定"
      category="教育"
      price="無料"
      release="2014/11/28"
      language="日本語・英語"
      lastUpdate="2014/12/24"
      version="1.7"
    />
  </Layout>
)

export default IndexPage
